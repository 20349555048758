import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import PageHeader from "./PageHeader"
import titleBelow from "../images/titleBelow.svg"
import { AllHuldaas } from "../graphql/AllHuldaasQuery"
import { useTranslation } from "react-i18next"
import Card from "../components/Card"
import { GeneralDescText } from "../graphql/QueryById"
import GeneralDescription from "../components/GeneralDescription"
import PageTitle from "../components/PageTitle"
import SEO from '../components/seo'

const HuldaasMainPageTemplate = () => {
  const headerImage = HuldaasDesc()[0].image ?? undefined
  const headerTitle = HuldaasDesc()[0].name ?? undefined
  const md = HuldaasDesc()[0].md ?? undefined

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const allHuldaas = AllHuldaas() ?? undefined
  const totalProduct = allHuldaas.length
  const [componentData, setComponentData] = useState(allHuldaas)
  useEffect(() => {
    setComponentData(allHuldaas)
  }, [currentLanguage])

  const titleChimeg = (
    <img
      src={titleBelow}
      alt="Title chimeg"
      className="w-24 py-4 text-gray-400 opacity-80"
    />
  )

  const cards = componentData.map((item, index) => {
    return <Card key={index} data={item} />
  })

  // const oneProduct = cards.length
  // console.log(cards)

  return (
    <Layout>
      <SEO title={headerTitle} />
      {/* <PageHeader image={headerImage} title={headerTitle} tall /> */}
      <PageTitle title={headerTitle} />
      <GeneralDescription desc={md} sectionName={headerTitle} />
      <div className="max-w-screen-lg mx-auto my-10">
        {/* <div className="flex flex-col items-center justify-center">
          <h2 className="px-2 pt-2 font-semibold text-center uppercase sm:pt-4">
            {GeneralDescText()}
          </h2>
          <span className="">{titleChimeg}</span>
          <div className="">{md}</div>
        </div> */}
        <div className="relative z-10 grid grid-cols-2 gap-4 mx-2 mt-10 md:p-10 md:bg-white md:grid-cols-4 sm:grid-cols-3 md:mx-4 lg:mx-0">
          {cards}
        </div>
      </div>
    </Layout>
  )
}

export default HuldaasMainPageTemplate
